import React, {useMemo, useState, useEffect, useCallback} from 'react';
import {Btn, FlexColumnSection, FlexDiv, FlexRowSection, Header} from "../../../styles/globalStyles";
import {getMembershipPriceText} from "../../../helpers/functions";
import Quantity from "../../../components/UI/Quantity";
import {useSelector, useDispatch} from "react-redux";
import {t} from "i18next";
import { Image } from 'antd';
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import styled from "styled-components";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'

const ShopItemDirect = (props) => {
    const { item, onPurchase, isModal } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);
    const [itemCopy, setItemCopy] = useState(null);
    const {isMobile} = useScreenSize()

    useEffect(() => {
        if(item && !itemCopy) {
            setItemCopy({...item})
        }
    }, [item]);

    const onQuantityChange = (quantity) => {
        setItemCopy(prev => ({...prev, quantity: quantity}))
    }

    const getDesktopJSX = useCallback(() => {
        if(isModal) {
            return (
                <FlexDivExtender isMobile={false} dir={'row'} flex={'unset'} gap={'20px'} padding={'4px 0 0'}>
                    {itemCopy.image && <Image width={300} src={itemCopy.image} preview={false}/>}
                    <FlexColumnSection justify={'space-between'} gap={'15px'}>
                        <FlexColumnSection gap={'13px'} flex={'unset'}>
                            <FlexColumnSection gap={'5px'}>
                                <Header>{itemCopy.name}</Header>
                                <span style={{fontSize: '20px'}}>{getMembershipPriceText(itemCopy, currencySymbol)}</span>
                            </FlexColumnSection>
                            <div style={{maxHeight: '400px', overflowY: 'auto', whiteSpace: 'pre-line'}}>{itemCopy.description}</div>
                        </FlexColumnSection>
                        <FlexDiv dir={'row'} gap={'10px'}>
                            <div style={{width: '88px'}}><Quantity onChange={onQuantityChange}/></div>
                            <div style={{display: 'flex', flex: 1}}><Btn type={'primary'} onClick={() => onPurchase(itemCopy)} width={'100%'}>{t('purchase')}</Btn></div>
                        </FlexDiv>
                    </FlexColumnSection>
                </FlexDivExtender>
            )
        } else {
            return (
                <FlexDiv dir={'row'} gap={'20px'}>
                    {itemCopy.image && <Image width={300} height={300} src={itemCopy.image} preview={false}/>}
                    <InfoWrapper justify={'space-between'} gap={'15px'} height={'min-content'}>
                        <FlexColumnSection gap={'10px'}>
                            <span style={{fontSize: '20px'}}>{getMembershipPriceText(itemCopy, currencySymbol)}</span>
                            <FlexColumnSection gap={'10px'} overflow={'auto'}>
                                <div style={{whiteSpace: 'pre-line'}}>{itemCopy.description}</div>
                            </FlexColumnSection>
                        </FlexColumnSection>
                        {itemCopy.image &&
                        <FlexDiv dir={'row'} gap={'10px'}>
                            <div style={{width: '88px'}}><Quantity onChange={onQuantityChange}/></div>
                            <Btn type={'primary'} onClick={() => onPurchase(itemCopy)} style={{flex: 1}} width={'100%'}>{t('purchase')}</Btn>
                        </FlexDiv>}
                    </InfoWrapper>
                    {!itemCopy.image &&
                    <QuantityWrapper flex={'unset'} gap={'20px'}>
                        <FlexRowSection gap={'20px'}>
                            <span>{t('quantity')}</span>
                            <Quantity onChange={onQuantityChange}/>
                        </FlexRowSection>
                        <Btn type={'primary'} onClick={() => onPurchase(itemCopy)} style={{flex: 1}} width={'100%'}>{t('purchase')}</Btn>
                    </QuantityWrapper>}
                </FlexDiv>
            )
        }
    },[itemCopy])

    if(!itemCopy) return null

    return (
        <>
            {isMobile ?
                <FlexDivExtender isMobile={true} dir={'column'} flex={'unset'} gap={'20px'} padding={isModal ? 0 : '0 16px'}>
                    <FlexColumnSection justify={'space-between'} gap={'15px'}>
                        <FlexColumnSection gap={'10px'}>
                            {isModal && <Header>{item.name}</Header>}
                            <span style={{fontSize: '20px'}}>{getMembershipPriceText(itemCopy, currencySymbol)}</span>
                            <FlexColumnSection gap={'10px'} overflow={'auto'}>
                                <div style={{whiteSpace: 'pre-line'}}>{itemCopy.description}</div>
                                {itemCopy.image && <Image width={'100%'} src={itemCopy.image} preview={false}/>}
                            </FlexColumnSection>
                        </FlexColumnSection>
                        <FlexDiv dir={'column'} gap={'10px'}>
                            <div style={{width: '100%'}}><Quantity onChange={onQuantityChange}/></div>
                            <Btn type={'primary'} onClick={() => onPurchase(itemCopy)} width={'100%'}>{t('purchase')}</Btn>
                        </FlexDiv>
                    </FlexColumnSection>
                </FlexDivExtender>
                :
                <>{getDesktopJSX()}</>
            }
        </>

    );
}

export default ShopItemDirect;

const InfoWrapper = styled(FlexColumnSection)`
    padding: 24px;
    background-color: ${Colors.white};
    border-radius: 5px;
    max-width: 500px;
`;

const QuantityWrapper = styled(FlexColumnSection)`
    padding: 24px;
    background-color: ${Colors.white};
    border-radius: 5px;
    width: 200px;
    height: min-content;
`;

const FlexDivExtender = styled(FlexDiv)`
    height: ${({isMobile}) => isMobile ? '100%' : 'auto'}
`;
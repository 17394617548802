import * as yup from "yup";
import React from 'react';
import PersonalDetails from "./steps/PersonalDetails";
import Memberships from "./steps/Memberships";
import {t} from "i18next";
import Payment from "./steps/Payment";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import {store} from "@arboxappv4/shared/src/redux/store";
import Success from "./steps/Success";
import {flowOptions, getSuccessProp} from "./FlowOptions";
import Waiver from "./steps/waiver/Waiver";
import {
    questionTypes,
    signatureTypes,
    signatureTypesEnum,
    bookingTypes,
    membershipTypes
} from "@arboxappv4/shared/src/helpers/constants";
import {CONST} from "@arboxappv4/shared/src/constants/Forms";
import dayjs from "dayjs";
import SingleItemPage from "./steps/SingleItemPage";
import {checkIfHPGroupDisplay, getHPGroupIdFromLocation, isValidIsraeliID} from "../helpers/functions";
import GroupSessions from "./steps/groupSessions/GroupSessions";
import ServiceTypeSelection from "./steps/appointment/ServiceTypeSelection";
import AvailabilitySlots from "./steps/appointment/AvailabilitySlots";
import Courses from "./steps/courses/Courses";
import Shop from "./steps/shop/Shop";
import ChildDetails from "./steps/courses/ChildDetails";
import ChildSelection from "./steps/courses/ChildSelection";
import TrialPersonalDetails from "./steps/TrialPersonalDetails";
import {FlexRowSection} from "../styles/globalStyles";
import ShareButton from "../components/ShareButton";

export const stepOptions = {
    PERSONAL_DETAILS: 'PERSONAL_DETAILS',
    SELECT_MEMBERSHIP: 'SELECT_MEMBERSHIP',
    DIRECT_ITEM: 'DIRECT_ITEM',
    WAIVER: 'WAIVER',
    PAYMENT: 'PAYMENT',
    SUCCESS: 'SUCCESS',
    GROUP_SESSIONS: 'GROUP_SESSIONS',
    SERVICE_TYPE: 'SERVICE_TYPE',
    AVAILABILITY_SLOT: 'AVAILABILITY_SLOT',
    COURSES: 'COURSES',
    SHOP: 'SHOP',
    COURSE_PARENT_DETAILS: 'COURSE_PARENT_DETAILS',
    COURSE_CHILD_DETAILS: 'COURSE_CHILD_DETAILS',
    COURSE_CHILD_SELECTION: 'COURSE_CHILD_SELECTION',
    TRIAL_GROUP_DETAILS: 'TRIAL_GROUP_DETAILS'
}

export const stepValidationSchema = () => ({
    PERSONAL_DETAILS: personalDetailsValidation(),
    SELECT_MEMBERSHIP: yup.object().shape({
        continueWithoutMembership: yup.boolean().required(),
        cart: yup.object().nullable().when("continueWithoutMembership", {
            is: false,
            then: () => yup.object().shape({
                id: yup.number().required(),
                start: yup.string().required(t('mandatory-field'))
            }),
            otherwise: () => yup.object().nullable()
        }),
    }),
    WAIVER: waiverValidation(),
    PAYMENT: yup.object().shape({
        payment_info: yup.object().required(),
    }),
    DIRECT_ITEM: yup.object().shape({
        cart: yup.object().shape({
            id: yup.number().required(),
            start: yup.string().required(t('mandatory-field'))
        }),
        locations_box_fk: yup.number().positive().required(t('mandatory-field')),
    }),
    SUCCESS: yup.object().shape({}),
    GROUP_SESSIONS: yup.object().shape({
        action: yup.string().oneOf([bookingTypes.INSERT_STAND_BY, bookingTypes.INSERT_SCHEDULE_USER, bookingTypes.CANCEL_SCHEDULE_USER, bookingTypes.CANCEL_WAIT_LIST]),
        schedule: yup.object().shape({
            id: yup.number().required()
        }),
        id: yup.number().nullable().when("action", {
            is: action => action === bookingTypes.INSERT_STAND_BY || action === bookingTypes.INSERT_SCHEDULE_USER,
            then: () => yup.number().required()
        }),
        payForSlot: yup.boolean().nullable().when("action", {
            is: action => action === bookingTypes.INSERT_STAND_BY || action === bookingTypes.INSERT_SCHEDULE_USER,
            then: () => yup.boolean().required()
        }),
    }),
    SERVICE_TYPE: yup.object().shape({
        service: yup.object().shape({
            id: yup.number().required()
        })
    }),
    AVAILABILITY_SLOT: yup.object().shape({
        schedule: yup.object().shape({
            id: yup.number().required()
        }),
        id: yup.number().required(),
        payForSlot: yup.boolean().required(),
    }),
    COURSES: yup.object().shape({
        course: yup.object().shape({
            id: yup.number().required()
        }),
        serviceId: yup.number().required()
    }),
    SHOP: yup.object().shape({
        cart: yup.object().shape({
            id: yup.number().required(),
        }),
        locations_box_fk: yup.number().positive().required(t('mandatory-field')),
    }),
    COURSE_PARENT_DETAILS: personalDetailsValidation(),
    COURSE_CHILD_DETAILS: yup.object().shape({
        child: courseChildDetailsValidation()
    }),
    COURSE_CHILD_SELECTION: yup.object().shape({
        isNewChild: yup.boolean().required(),
        child: yup.object().when('isNewChild',{
            is: true,
            then: () => courseChildDetailsValidation(),
            otherwise: () => yup.object().shape({
                child_id: yup.number().required(),
            })
        })
    }),
    TRIAL_GROUP_DETAILS: personalDetailsValidation().concat(yup.object().shape({
        cart: yup.object().shape({
            id: yup.number().required(),
        }),
        locations_box_fk: yup.number().positive().required(t('mandatory-field')),
        schedule: yup.object().shape({
            id: yup.number().required()
        }),
    }))
})

export const stepInitialValues = (params = null) => {
    return {
        PERSONAL_DETAILS: personalDetailsInitialValues(params),
        SELECT_MEMBERSHIP: {
            cart: params?.membership ?? null,
            continueWithoutMembership: false
        },
        WAIVER: waiverInitialValue(),
        PAYMENT: { payment_info: null },
        DIRECT_ITEM: {
            cart: params?.target,
            locations_box_fk: getRegisterLocation(params?.target ?? null),
        },
        SUCCESS: {},
        GROUP_SESSIONS: {
            schedule: params?.target ?? null,
            id: null,
            payForSlot: null,
            action: params?.target?.booking_option ?? null
        },
        SERVICE_TYPE: {
            service: null
        },
        AVAILABILITY_SLOT: {},
        COURSES: {
            course: params?.target ?? null, //basically only for direct page
            serviceId: null,
        },
        SHOP: {
            cart: params?.target ?? null,
            locations_box_fk: params?.target?.location_box_fk ?? null,
        },
        COURSE_PARENT_DETAILS: personalDetailsInitialValues(params),
        COURSE_CHILD_DETAILS: {
            child: {
                first_name: '',
                last_name: '',
                phone: '',
                birthday: null,
                personal_id: '',
                gender: ''
            }
        },
        COURSE_CHILD_SELECTION: {
            isNewChild: false,
            child: {}
        },
        TRIAL_GROUP_DETAILS: getTrialSessionInitialValues(params)
}}

export const renderStep = {
    PERSONAL_DETAILS: (params) => {
        const flowName = store.getState().stepper.flowName
        const displayHPGroups = checkIfHPGroupDisplay() && flowName === flowOptions.REGISTER
        const hideLocation = params?.locationParams?.membership && !displayHPGroups ? params?.locationParams?.membership : ([flowOptions.GROUP_SESSION, flowOptions.DIRECT_GROUP_SESSION, flowOptions.STAFF_APPOINTMENT, flowOptions.SPACE_APPOINTMENT, flowOptions.COURSE, flowOptions.DIRECT_COURSE, flowOptions.PRODUCT_PURCHASE].includes(flowName));
        let selectedLocationGroupId = null
        if(params?.locationParams?.membership && displayHPGroups) {
            selectedLocationGroupId = getHPGroupIdFromLocation(params.locationParams.membership.location_box_fk)
        }
        return <PersonalDetails hideLocation={hideLocation} HPGroupId={selectedLocationGroupId} {...params}/>
    },
    SELECT_MEMBERSHIP: (params) => {
        const flowName = store.getState().stepper.flowName
        const skipStep = params?.locationParams?.membership ?? false;
        const blockOnLoggedOut = flowName === flowOptions.MEMBERSHIP_PURCHASE;
        const checkIfNoMembershipAllowed = flowName === flowOptions.REGISTER;
        const HPGroupDisplay = flowName === flowOptions.MEMBERSHIP_PURCHASE;
        const hideLocation = !!params?.values?.locations_box_fk && flowName !== flowOptions.MEMBERSHIP_PURCHASE
        return <Memberships skipStep={skipStep} blockOnLoggedOut={blockOnLoggedOut} checkIfNoMembershipAllowed={checkIfNoMembershipAllowed} HPGroupDisplay={HPGroupDisplay} hideLocation={hideLocation} {...params}/>
    },
    WAIVER: (params) => (<Waiver {...params}/>),
    PAYMENT: (params) => {
        const flowName = store.getState().stepper.flowName
        let additional = {}
        let idPropOverride = null
        if([flowOptions.GROUP_SESSION, flowOptions.DIRECT_GROUP_SESSION, flowOptions.STAFF_APPOINTMENT, flowOptions.SPACE_APPOINTMENT].includes(flowName))
            idPropOverride = 'id'
        if([flowOptions.COURSE, flowOptions.DIRECT_COURSE, flowOptions.COURSE_CHILD_REGISTRATION].includes(flowName)) {
            const course = store.getState().stepper.flags.courseInfo
            idPropOverride = 'serviceId'
            if(course?.allow_mid_booking && (course?.has_started || course?.past)) {
                additional.startOverride = dayjs().format(DB_DATE_FORMAT)
            }
        }
        return <Payment idPropOverride={idPropOverride} {...additional} {...params}/>
    },
    DIRECT_ITEM: (params) => (<SingleItemPage {...params}/>),
    SUCCESS: (params) => (<Success {...getSuccessProp(params)}/>),
    GROUP_SESSIONS: (params) => {
        const flowName = store.getState().stepper.flowName
        return <GroupSessions isDirectSession={flowName === flowOptions.DIRECT_GROUP_SESSION} {...params}/>
    },
    SERVICE_TYPE: (params) => {
        const flowName = store.getState().stepper.flowName
        const isCoachAppointment = flowName === flowOptions.STAFF_APPOINTMENT
        return <ServiceTypeSelection isCoachAppointment={isCoachAppointment} {...params}/>
    },
    AVAILABILITY_SLOT: (params) => {
        const flowName = store.getState().stepper.flowName
        const isCoachAppointment = flowName === flowOptions.STAFF_APPOINTMENT
        return <AvailabilitySlots isCoachAppointment={isCoachAppointment} {...params}/>
    },
    COURSES: (params) => {
        const flowName = store.getState().stepper.flowName
        return <Courses isDirectCourse={flowName === flowOptions.DIRECT_COURSE} {...params}/>
    },
    SHOP: (params) => {
        const flowName = store.getState().stepper.flowName
        const isOneTimeLink = params?.target?.isOneTimeLink ?? false
        return <Shop isDirectItem={flowName === flowOptions.DIRECT_PRODUCT_PURCHASE} isOneTimeLink={isOneTimeLink} {...params}/>
    },
    COURSE_PARENT_DETAILS: (params) => {
        const additional = {
            hideLocation: true,
            helperText: t('course-parent-helper-text')
        }
        return <PersonalDetails {...additional} {...params}/>
    },
    COURSE_CHILD_DETAILS: (params) => {
        return <ChildDetails {...params}/>
    },
    COURSE_CHILD_SELECTION: (params) => {
        return <ChildSelection {...params}/>
    },
    TRIAL_GROUP_DETAILS: (params) => {
        return <TrialPersonalDetails {...params}/>
    }
}

export const stepHeader = ({initProps, values}) => ({
    PERSONAL_DETAILS: () => t('create-account'),
    SELECT_MEMBERSHIP: () => t('select-membership'),
    WAIVER: () => t('waiver'),
    PAYMENT: () => t('payment-header'),
    DIRECT_ITEM: () => initProps?.target?.name ?? '',
    SUCCESS: () => '',
    GROUP_SESSIONS: () => {
        const flowName = store.getState().stepper.flowName
        return flowName === flowOptions.GROUP_SESSION ? t('group-sessions') : initProps?.target?.box_categories.name
    },
    SERVICE_TYPE: () => {
        const flowName = store.getState().stepper.flowName
        const isCoachAppointment = flowName === flowOptions.STAFF_APPOINTMENT
        return t(`select-service-type-${isCoachAppointment? 'staff' : 'space'}`)
    },
    AVAILABILITY_SLOT: () => values.service?.name,
    COURSES: () => {
        const flowName = store.getState().stepper.flowName
        return [flowOptions.COURSE, flowOptions.COURSE_CHILD_REGISTRATION].includes(flowName) ? t('courses') : initProps?.target?.box_categories.name
    },
    SHOP: () => {
        const flowName = store.getState().stepper.flowName
        return flowName === flowOptions.PRODUCT_PURCHASE ? t('products') : initProps?.target?.name
    },
    COURSE_PARENT_DETAILS: () => t('course-parent-details'),
    COURSE_CHILD_DETAILS: () => t('course-child-details'),
    COURSE_CHILD_SELECTION: () => t('course-child-selection'),
    TRIAL_GROUP_DETAILS: () => initProps?.target?.box_categories.name
})

export const onBack = {
    PERSONAL_DETAILS: () => null,
    SELECT_MEMBERSHIP: () => null,
    WAIVER: (params) => {
        if(params.setFieldValue) {
            // params.setFieldValue('digitalFormInfo', {form: null, digital_form: null})
        }
    },
    PAYMENT: () => null,
    DIRECT_ITEM: () => null,
    SUCCESS: () => null,
    GROUP_SESSIONS: () => null,
    SERVICE_TYPE: () => null,
    AVAILABILITY_SLOT: (params) => {
        if(params.setFieldValue) {
            params.setFieldValue('service', null)
        }
    },
    COURSES: () => null,
    SHOP: () => null,
    COURSE_PARENT_DETAILS: () => null,
    COURSE_CHILD_DETAILS: () => null,
    COURSE_CHILD_SELECTION: () => (params) => {
        if(params.setFieldValue) {
            params.setFieldValue('isNewChild', false)
            params.setFieldValue('child', {})
        }
    },
    TRIAL_GROUP_DETAILS: () => null,
}

const personalDetailsValidation = () => {
    const flowName = store.getState().stepper.flowName
    const box = store.getState().site.box
    let schema = yup.object().shape({
        first_name: yup.string().min(2, t('min-chars', {min: 2})).required(t('mandatory-field')),
        last_name: yup.string().min(2, t('min-chars', {min: 2})).required(t('mandatory-field')),
        email: yup.string().email(t('email-invalid-format')).required(t('email-required-field')),
        phone: yup.string().matches(/^(\+?\d{5,15})$/, t('invalid-phone')).required(t('mandatory-field')).min(5, t('min-chars', {min: 5})).required(t('mandatory-field')),
        birthday: yup.string().nullable().test('date-format', 'invalid date', function(value){
            return !value || (dayjs(value, DB_DATE_FORMAT).format(DB_DATE_FORMAT) === value)
        }),
        allow_mailing_list: yup.string().oneOf(['yes','no']),
        allow_sms: yup.string().oneOf(['yes','no']),
        locations_box_fk: yup.number().positive().required(t('mandatory-field')),
        lead_id: yup.number().positive().nullable(),
        personal_id: yup.string().when([], {
            is: () => box?.synced_items_rivhit,
            then: () => yup.string().required(t('mandatory-field')).matches(/^[0-9]*$/, t('numbers-only')).test('length-test', t('personal-id-length-error'), val => !val || (val && (val.length === 0 || isValidIsraeliID(val)))), //check starts with 0 and then 9 digits or 972 and then
            otherwise: () => yup.string().matches(/^[0-9]*$/, t('numbers-only')).test('length-test', t('personal-id-length-error'), val => !val || (val && (val.length === 0 || isValidIsraeliID(val))))
        }),
        customFields: getCustomFieldValueValidationSchema()
    })
    return schema;
}

const courseChildDetailsValidation = () => {
    return yup.object().shape({
        first_name: yup.string().min(2, t('min-chars', {min: 2})).required(t('mandatory-field')),
        last_name: yup.string().min(2, t('min-chars', {min: 2})).required(t('mandatory-field')),
        phone: yup.string().matches(/^(\+?\d{5,15})$/, t('invalid-phone')),
        birthday: yup.string().nullable().test('date-format', 'invalid date', function(value){
            return !value || (dayjs(value, DB_DATE_FORMAT).format(DB_DATE_FORMAT) === value)
        }),
        personal_id: yup.string().required(t('mandatory-field')).matches(/^[0-9]*$/, t('numbers-only')).test('length-test', t('personal-id-length-error'), val => val && val.length === 9),
        customFields: getCustomFieldValueValidationSchema()
    })
}

const waiverValidation = () => {
    const asyncData = store.getState().stepper.asyncData
    if(asyncData && asyncData[stepOptions.WAIVER]) {
        return formValidation(asyncData[stepOptions.WAIVER])
    }
    return yup.object().shape({})
}

const formValidation = (formData) => {
    let formValidationSchema = yup.object().shape({})
    if(formData.digital_form.signature_options === signatureTypesEnum.CHECKBOX_AND_SIGNATURE || formData.digital_form.signature_options === signatureTypesEnum.SIGNATURE_ONLY) {
        formValidationSchema = formValidationSchema.concat(yup.object().shape({
            signature_data: yup.string().required(t('mandatory-field'))
        }))
    }
    if(formData.digital_form.signature_options === signatureTypesEnum.CHECKBOX_AND_SIGNATURE || formData.digital_form.signature_options === signatureTypesEnum.CHECKBOX_ONLY) {
        formValidationSchema = formValidationSchema.concat(yup.object().shape({
            agree: yup.boolean().oneOf([true],t('form-agree-checkbox-error')).required(t('mandatory-field'))
        }))
    }
    const questions = formData.digital_form_builder.find(type => type.property_type === "questions")
    if(questions) {
        formValidationSchema = formValidationSchema.concat(yup.object().shape({
            questions: yup.array(yup.object().shape({
                type: yup.string().oneOf([questionTypes.MULTI_CHOICE, questionTypes.OPEN, questionTypes.YES_NO, questionTypes.YES_NO_ADV]).required(),
                multi_choice: yup.boolean().required(),
                required: yup.boolean().required(),
                user_answer: yup.string().when(['type','multi_choice', 'required'], ([type, multi_choice, required], schema) => {
                    switch(type) {
                        case questionTypes.OPEN:
                            return required ? schema.required(t('mandatory-field')) : schema.nullable();
                        case questionTypes.YES_NO:
                        case questionTypes.YES_NO_ADV:
                            return required ? yup.string().required(t('mandatory-field')).oneOf(['true', 'false']) : yup.string().nullable().oneOf(['true', 'false']);
                        case questionTypes.MULTI_CHOICE:
                            if(multi_choice) {
                                return schema.nullable()
                            } else {
                                return required ? schema.required(t('mandatory-field')) : schema.nullable();
                            }
                        default:
                            return schema
                    }
                }),
                checked_array: yup.array().when(['type','multi_choice', 'required'], ([type, multi_choice, required], schema) => {
                    if(type === questionTypes.MULTI_CHOICE && multi_choice) {
                        return required ? schema.of(
                            yup.object().shape({
                                id: yup.number().required(),
                                checked: yup.boolean().required(),
                                answer_text: yup.string().required()
                            })).required(t('mandatory-field')).min(1) : schema
                    }
                    return schema.nullable()
                }),
            }))
        }))
    }
    return yup.object().shape({
        digitalFormInfo: yup.object().shape({
            form: formValidationSchema,
            digital_form: yup.object().shape({
                id: yup.number().required()
            })
        })
    })
}

const waiverInitialValue = () => {
    const asyncData = store.getState().stepper.asyncData
    if(asyncData && asyncData[stepOptions.WAIVER]) {
        const form = asyncData[stepOptions.WAIVER]
        const questions = form.digital_form_builder.find((item => item.property_type === CONST.TYPE_QUESTIONS));
        const newQuestions = questions?.digital_form_question.reduce((acc, q) => {
            if(q.type === questionTypes.MULTI_CHOICE) {
                const newAnswers = q.answers.map(answer => ({...answer, checked: false}))
                return [...acc, {...q, answers: newAnswers}]
            } else {
                return [...acc, q]
            }
        }, [])
        const hasCheckbox = form.digital_form.signature_options === signatureTypesEnum.CHECKBOX_AND_SIGNATURE || form.digital_form.signature_options === signatureTypesEnum.CHECKBOX_ONLY
        return {
            digitalFormInfo: {
                form: {questions: newQuestions ? newQuestions : null, agree: !hasCheckbox},
                digital_form: form.digital_form
            }
        }
    }
    return {}
}

const personalDetailsInitialValues = (params) => {
    const convertedLeadData = params?.convertedLeadData;
    let values = {
        first_name: convertedLeadData?.first_name ?? '',
        last_name: convertedLeadData?.last_name ?? '',
        email: convertedLeadData?.email ?? '',
        phone: convertedLeadData?.phone ?? '',
        birthday: convertedLeadData?.birthday ?? null,
        allow_mailing_list: 'yes',
        allow_sms: 'yes',
        locations_box_fk: getRegisterLocation(params?.membership ?? null),
        gender: convertedLeadData?.gender && ['male', 'female'].includes(convertedLeadData.gender) ? convertedLeadData.gender : '',
        lead_id: convertedLeadData?.user_fk ?? null,
        personal_id: '',
        isConvertedFromSite: !!convertedLeadData?.user_fk // aka: convertedLeadData?.user_fk ? true : false
    }
    return values;
}

const getTrialSessionInitialValues = (params) => {
    const membership = params?.target?.series?.membership_types.find(item => item.type === membershipTypes.TRIAL)
    if(!membership) return {}
    membership.start = params.target.date
    return {
        ...personalDetailsInitialValues(params),
        schedule: params.target,
        cart: membership,
        locations_box_fk: getRegisterLocation(membership),
    }
}

const getRegisterLocation = (membership = null) => {
    let membershipLocation = membership?.location_box_fk ?? null
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const loggedIn = store.getState().auth.loggedIn
    const globalLocation = store.getState().site.selectedLocation

    if(membershipLocation && params.overrideLocation === 'true' && !loggedIn) {
        if(membership.payment_locations && membership.payment_locations.includes(globalLocation?.id)) {
            membershipLocation = globalLocation?.id
        }
    }
    return membershipLocation
}

const getCustomFieldValueValidationSchema = () => {
    return yup.array(yup.object().shape({
        value: yup.string().nullable()
            .when('props', {
                is: value => value.isRequired,
                then: schema => schema.required(t('mandatory-field'))
            })
            .when('custom_field_type', {
                is: value => value === 'phone',
                then: schema => schema.matches(/^(\+?\d{5,15})$/, t('invalid-phone')).min(5, t('min-chars', {min: 5})),
            }),
    }));

}

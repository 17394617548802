import React, {useEffect, useMemo, useState} from 'react';
import {FlexColumnSection} from "../../styles/globalStyles";
import {Spin} from "antd";
import {useSelector, useDispatch} from "react-redux";
import useScreenSize from "../../helpers/hooks/useScreenSize";
import {DB_DATE_FORMAT, LOADING_PAGE, LOADING_PAYMENT} from "@arboxappv4/shared/src/constants/constants";
import dayjs from "dayjs";
import {updateCurrStep, updateError} from "@arboxappv4/shared/src/redux/stepperSlice";
import {t} from "i18next";
import {callSiteLog} from "../../helpers/functions";
import {
    paymentEnterEventListenerLog,
    paymentErrorResponseLog, paymentIframeLog, paymentLogGroup, paymentStopListeningResponseLog,
    paymentSubmitLog,
    paymentSuccessResponseLog
} from "@arboxappv4/shared/src/helpers/constants";
import {setLoadingType} from "@arboxappv4/shared/src/redux/siteSlice";

const Payment = (props) => {
    const { values, setFieldValue, handleSubmit, idPropOverride, startOverride } = props;
    const box = useSelector(state => state.site.box)
    const language = useSelector(state => state.site.language)
    const location = useSelector(state => state.site.selectedLocation)
    const token = useSelector(state => state.auth.token)
    const userBox = useSelector(state => state.user.activeUsersBoxes)
    const error = useSelector(state => state.stepper.error)
    const stepperConfig = useSelector(state => state.stepper)
    const [loading, setLoading] = useState(true);
    const [iframeSrc, setIframeSrc] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()
    const hideBack = useMemo(() => stepperConfig.steps.length === 1, [stepperConfig.steps])

    useEffect(() => {
        const listenToMessages = (event) => {
            if((process.env.PAYMENTS_URL ?? '').includes(event.origin)) {
                callSiteLog(paymentEnterEventListenerLog, event.data, paymentLogGroup)
            }
            if(event.data.type === "PAYMENT_RESPONSE_SUCCESS") {
                console.log('listening.. ', event.data.data)
                callSiteLog(paymentSuccessResponseLog, event.data.data, paymentLogGroup)
                setFieldValue('payment_info', event.data.data)
            }
            if(event.data.type === "PAYMENT_RESPONSE_ERROR") {
                console.log('error!!!! ', event.data)
                callSiteLog(paymentErrorResponseLog, event.data, paymentLogGroup)
                dispatch(updateError(t('payment-generic-error')))
            }
            if(event.data.type === "PAYMENT_SITE_BACK") {
                const stepIndex = stepperConfig.steps.indexOf(stepperConfig?.currStep);
                dispatch(updateCurrStep(stepperConfig.steps[stepIndex - 1]))
            }
        }

        window.addEventListener('message', listenToMessages);
        return () => {
            callSiteLog(paymentStopListeningResponseLog, {}, paymentLogGroup)
            window.removeEventListener("message", listenToMessages);
            setSubmitted(false)
        };
    }, []);

    useEffect(() => {
        if(values.payment_info && !submitted) {
            callSiteLog(paymentSubmitLog, values, paymentLogGroup)
            handleSubmit()
            setSubmitted(true)
        }
    }, [values.payment_info, submitted]);

    useEffect(() => {
        if(box && language && values && Object.keys(values).length > 0 && !error) {
            setIframeSrc(getIframeSrc())
        }
    }, [box, language, values, userBox]);


    useEffect(() => {
        if(error) {
            callSiteLog(paymentErrorResponseLog, {error, values}, paymentLogGroup)
            setFieldValue('payment_info', null)
            setIframeSrc(null)
            setSubmitted(false)
            setShowErrorScreen(true)
            // setTimeout(() => setIframeSrc(getIframeSrc()), 10)
        }
    }, [error]);

    useEffect(() => {
        callSiteLog(paymentIframeLog, {iframeSrc, values}, paymentLogGroup)
    }, [iframeSrc]);

    // name and phone are only for meshulam
    const getUserName = () => {
        const firstName = userBox?.first_name ?? values?.first_name
        const lastName = userBox?.last_name ?? values?.last_name
        if(firstName && lastName) {
            if(firstName.length >= 2 && lastName.length >= 2) {
                return `&name=${firstName} ${lastName}`
            }
        }

        return ''
    }

    const getUserPhone = () => {
        const phone = userBox?.phone ?? values?.phone
        if(phone && (phone[0] === '0' || phone.startsWith('972')) && phone.length >= 10) {
            return `&phone=${phone}`
        }
        return ''
    }

    const getQuantity = () => {
        if(values.cart && values.cart.quantity > 1) {
            return `&quantity=${JSON.stringify({[values.cart.id]: values.cart.quantity})}`
        }
        return ''
    }

    const getStartDates = () => {
        const today = dayjs().format(DB_DATE_FORMAT)
        if(values.cart?.start && (values.cart.start > today)) {
            return `&startDates=${JSON.stringify({[values.cart.id]: values.cart.start})}`
        } else if (startOverride) {
            return `&startDates=${JSON.stringify({[idPropOverride ? values[idPropOverride] : values.cart?.id]: startOverride})}`
        }
        return ''
    }

    const getIframeSrc = () => {
        return `${process.env.PAYMENTS_URL}?box_id=${box.id}&lang=${language?.code}&locations_box_id=${values.locations_box_fk ?? location.id}&cart=${JSON.stringify([idPropOverride ? values[idPropOverride] : values.cart?.id])}&token=${token}&hideBack=${hideBack}${getUserName()}${getUserPhone()}${getQuantity()}${getStartDates()}`
    }

    return (
       <FlexColumnSection padding={isMobile ? '0 16px' : 0} align={'start'}>
           <Spin spinning={loading} style={{alignSelf: 'center', justifyContent:'center', flex: 1}}/>
           {iframeSrc &&
           <iframe frameBorder={0}
                   style={{width: '100%', flex: 1}}
                   onLoad={() => setLoading(false)}
                   src={iframeSrc}/>}
           {showErrorScreen && <div>{t('payment-error-screen-message')}</div>}
       </FlexColumnSection>
    );
};

export default Payment;
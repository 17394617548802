export const iconPrefix = {
    SOLID: 'SOLID',
    LIGHT: 'LIGHT',
}

export const signatureTypes = {
    1: 'SIGNATURE_ONLY',
    2: 'CHECKBOX_ONLY',
    3: 'CHECKBOX_AND_SIGNATURE',
    0: 'NONE'
}

export const signatureTypesEnum = {
    'SIGNATURE_ONLY': 1,
    'CHECKBOX_ONLY': 2,
    'CHECKBOX_AND_SIGNATURE': 3,
    'NONE': 0
}

export const questionTypes = {
    YES_NO: 'questionYesNo',
    YES_NO_ADV: 'questionYesNoAdv',
    MULTI_CHOICE: 'questionMultipleChoice',
    OPEN: 'questionOpen',
}

export const bookingTypes = {
    PAST: 'past',
    CANCEL_SCHEDULE_USER: 'cancelScheduleUser',
    CANCEL_WAIT_LIST: 'cancelWaitList',
    INSERT_SCHEDULE_USER: 'insertScheduleUser',
    INSERT_STAND_BY: 'insertStandby',
    INFO: 'info',
    SUCCESS_OPTIONS: 'successOptions',
    INVITE_FRIENDS: 'inviteFriends',
    ADD_TO_CALENDAR: 'addToCalendar',
}

export const sessionTypes = {
    CLASS: 'class',
    COURSE: 'workshop',
    APPOINTMENT: 'appointment',
    HUGIM: 'hugim'
}

export const membershipTypes = {
    PUNCH_CARD: 'session',
    PLAN: 'plan',
    PAY_FOR_SLOT: 'service',
    COURSE: 'workshop',
    TRIAL: 'trial'
}

export const customFieldTypes = {
    TEXT: 'text',
    SELECT: 'select',
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    DATE: 'date',
    PHONE: 'phone'
}

export const paymentSuccessResponseLog = 'paymentSuccessResponse'
export const paymentSubmitLog = 'paymentSubmit'
export const paymentErrorResponseLog = 'paymentErrorResponse'
export const paymentLogGroup = 'paymentLogGroup'
export const paymentIframeLog = 'paymentIframeSrc'
export const paymentStopListeningResponseLog = 'paymentStopListeningResponseLog'
export const paymentEnterEventListenerLog = 'paymentEnterEventListenerLog'

export const customFieldFlowNames = {
    USER: 'CUSTOM_FIELD_USER_SITE',
    LEAD: 'CUSTOM_FIELD_LEAD_SITE'
}